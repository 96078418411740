/* eslint-disable no-useless-computed-key */
// @noflow
import * as Sentry from '@sentry/browser'
import Cookies from 'js-cookie'

import { SubscriptionStatus } from '@/types'

type SentryProperties = {
  subscriptionId: number | string
  subscriptionStatus: SubscriptionStatus
}

document.addEventListener('DOMContentLoaded', (): void => {
  const trackingId = Cookies.get('tracking_id')
  const sentryCookie = Cookies.get('sentry_properties')

  let sentryProperties = {} as SentryProperties

  if (sentryCookie) {
    const decodedSentry = decodeURIComponent(sentryCookie)
    sentryProperties = JSON.parse(decodedSentry)
  }

  if (!trackingId) {
    throw new Error('Could not find the tracking Id')
  }

  const initSentry = (): void => {
    Sentry.init({
      dsn: process.env.SENTRY_FRONTEND_DSN,
      release: process.env.HEROKU_SLUG_COMMIT,
      environment: process.env.NODE_ENV,
      normalizeDepth: 10,
      ignoreErrors: [
        'cancelled',
        '已取消',
        'キャンセルしました',
        'anulowane',
        'cancelado'
      ]
    })

    const scope = Sentry.getCurrentScope()

    scope.setUser({
      id: trackingId,
      ['Subscription ID']: sentryProperties.subscriptionId,
      ['Subscription Status']: sentryProperties.subscriptionStatus
    })
  }

  const cookieConsent =
    localStorage.getItem('cookieConsent') || '{ "preferences": {} }'
  const consent = JSON.parse(cookieConsent)

  if (
    consent &&
    consent.state === 'accepted' &&
    consent.preferences.analytics
  ) {
    initSentry()
  }
})
